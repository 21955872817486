import React from "react";
import {Link} from "react-router-dom";
import "../assets/style/job.css"
import {FormControl, FormGroup, FormLabel} from "react-bootstrap";
export function JobForm(job,setJob){
    const handleChangeJob=(event =>{
    const key = event.target.name
    const value = event.target.value
    setJob({ ...job, [key]: value })
        //for rows on peut utiliser l'index pour dire que le rows[index] est l'element qu'on veut modifier ou ajouter
})
return (
    <>
        <FormGroup>
            <FormLabel>Titre</FormLabel>
            <FormControl type="text" name="title" value={job.title} onChange={handleChangeJob} required/>
        </FormGroup>
        <FormGroup>
            <FormLabel>Sous-Titre</FormLabel>
            <FormControl type="text" name="subtitle" value={job.subtitle} onChange={handleChangeJob} required/>
        </FormGroup>
        <FormGroup>
            <FormLabel>date1</FormLabel>
            <FormControl type="text" name="date1" value={job.date1} onChange={handleChangeJob} required/>
        </FormGroup>
        <FormGroup>
            <FormLabel>heure1</FormLabel>
            <FormControl type="text" name="hour1" value={job.hour1} onChange={handleChangeJob} required/>
        </FormGroup>
        <FormGroup>
            <FormLabel>lieu1</FormLabel>
            <FormControl type="text" name="place1" value={job.place1} onChange={handleChangeJob} required/>
        </FormGroup>
        <FormGroup>
            <FormLabel>Spectacle</FormLabel>
            <FormControl type="text" name="title1" value={job.title1} onChange={handleChangeJob} required/>
        </FormGroup>
    </>
)}
export function Job(content){
    // content.rows.map((row,index)=>{
    //     console.log(row)
    //     console.log(index)
    //     console.log(content.rows[index])
    // })
        return (
                <section id="jobs" className="jobs">
                    <div className="container">

                        <div className="section-title">
                            <h2>{content.title}</h2>
                            <p>{content.subtitle}</p>
                        </div>
                        {content.rows.map((row, index) => {
                            if (row.hasOwnProperty("subtitle")) {
                                return (
                                    <div key={"job-" + index} className="row d-flex justify-content-center">
                                        <div className="col-lg-8 d-flex align-items-stretch justify-content-center">
                                            <h4>
                                                <span className="jobtitle">{row.subtitle}</span>
                                            </h4>
                                        </div>
                                    </div>
                                )
                            } else {
                                return (
                                    <div key={"job-" + index} className="row d-flex justify-content-center">
                                        <div className="col-lg-8 d-flex align-items-stretch justify-content-center">
                                            <h4>
                                                <span className="jobtime">{content.rows[index].date}, {row.hour} </span>
                                                <span className="jobplace">{content.rows[index].place}</span>
                                                <span className="jobtitle">/{content.rows[index].title}</span>
                                            </h4>
                                        </div>
                                    </div>)
                            }
                        })}
                        <div className="section-title">
                            <p>{content.subtitle2}</p>
                        </div>
                        {content.rows2.map((row2, index2) => {
                            if (row2.hasOwnProperty("subtitle")) {
                                return (
                                    <div key={"job-" + index2} className="row d-flex justify-content-center">
                                        <div className="col-lg-8 d-flex align-items-stretch justify-content-center">
                                            <h4>
                                                <p>{row2.subtitle}</p>
                                            </h4>
                                        </div>
                                    </div>
                                )
                            } else {
                                return (
                                    <div key={"job-" + index2} className="row d-flex justify-content-center">
                                        <div className="col-lg-11 d-flex align-items-stretch justify-content-center" style={{paddingBottom:'20px'}}>
                                            <h4>
                                                <span
                                                    className="jobtime">{content.rows2[index2].date}, {row2.hour} </span>
                                                <span className="jobplace">{content.rows2[index2].place}</span>
                                                <span className="jobtitle">/{content.rows2[index2].title}</span>
                                            </h4>
                                        </div>
                                    </div>)
                            }
                        })}
                    </div>
                </section>
        )
}