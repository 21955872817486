import React from "react";
import {Image, Modal} from "react-bootstrap";
import '../assets/style/gallery.css'

export function GalleryForm(gallery,setGallery){

}
export function handlemodal(row,setData,setModalShow){
    setData({modalimgurl:row.imgurl,modaltitle:row.title,modalimgalt:row.imgalt,modallegende:row.legende})
    setModalShow(true);
}
export  function Gallery(content){
    const [modalShow, setModalShow] = React.useState(false);
    const [data,setData]=React.useState({modalimgurl:"",modaltitle:"",modalimgalt:"",modallegende:""});

        return (
            <section id="gallery" className="portfolio">
                <div className="container">
                    <div className="section-title">
                        <h2>{content.title}</h2>
                        <p>{content.content}</p>
                    </div>
                    <div className='container d-flex justify-content-center'>
                    <div className="row portfolio-container d-flex justify-content-center" >
                        {content.rows.map(row=>{
                        return(

                        <div className="col-lg-4 col-md-6 portfolio-item">
                            <div className="portfolio-img d-flex justify-content-center">
                                <div className='containeroverlay'>
                                <Image onClick={() => handlemodal(row, setData, setModalShow)} src={row.imgurl}
                                       className="img-fluid gallery-image imageoverlay" alt={row.imgalt}/>
                                {/*<div className="overlaylay">*/}
                                {/*    <div className="text">{row.title}<br/>*/}
                                {/*    <span>{row.legende}</span></div>*/}
                                {/*</div>*/}
                                </div>
                            </div>
                        </div>
                        )
                        })}
                    </div>
                    </div>
                    <Modal
                        size="lg"
                        centered
                        show={modalShow}
                        onHide={() => setModalShow(false)}
                    >
                        <Modal.Header closeButton>
                            {/*<Modal.Title >*/}
                            {/*    {data.modaltitle}*/}
                            {/*</Modal.Title>*/}
                        </Modal.Header>
                        <Modal.Body>
                            <Image src={data.modalimgurl} className="img-fluid gallery-image" alt={data.modalimgalt}/>
                            {/*<p>{data.modallegende}</p>*/}
                        </Modal.Body>
                    </Modal>
                </div>
            </section>
        )
}