import React from "react";
import {Link} from "react-router-dom";
import '../assets/style/footer.css'

export function FooterForm(footer,setFooter){
}
export  function Footer(content){
    return (
        <footer>
            <div className="container">
                <div className="row copyright">
                    <div className="col-md-6">
                        <p className="mb-md-0 text-center text-md-left">
                            &copy;{content.copyright}
                        </p>
                    </div>
                    <div className="col-md-6">
                        <p className="credit mb-md-0 text-center text-md-right">
                            Website by <Link to="rdlp.xyz">RDLP</Link>
                        </p>
                    </div>
                </div>
            </div>
        </footer>
    )
}