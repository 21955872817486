
import { useEffect } from "react"
import { useNavigate } from "react-router-dom"
import { deleteLocalStorage, JWT_KEY, USER_NAME } from "../service/localStorageService"


export default function Signout() {
    const navigate = useNavigate()
    const removemodal=async ()=>{
        var elms = document.querySelectorAll("*[class]")
        elms.forEach(element => {
            if (element.className === 'modal-backdrop fade show') {
                element.remove()
            }
            if (element.className === 'modal-backdrop show') {
                element.remove()
            }
        })
    }
    useEffect(() => {
        removemodal().then(()=>{
            deleteLocalStorage(USER_NAME)
            deleteLocalStorage(JWT_KEY).then(() => { navigate('/login') })
            navigate('/login')})
    }, [navigate])
    //setLocalStorage(JWT_KEY, '')

    //setLocalStorage(USER_KEY, '')
    //setUser('')
    //navigate('/about')
}