export const USER_KEY = 'user'
export const JWT_KEY = 'jwt'
export const USER_NAME ='name'

/**
 *
 * @param {string} key
 * @param {*} value
 */
export async function setLocalStorage(key, value) {

    const stringValue = JSON.stringify(value)
    localStorage.setItem(key, stringValue)
}

export function getLocalStorage(key) {
    const stringValue = localStorage.getItem(key)

    return JSON.parse(stringValue)
}
export async function deleteLocalStorage(key) {
    localStorage.removeItem(key)
}