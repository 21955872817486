import React, {useEffect, useState} from 'react'
import {insecureGet} from "../service/getDataService";
import '../assets/style/header.css'
import Container from "react-bootstrap/Container";
import Image from"react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
// import 'bootstrap/dist/css/bootstrap.min.css';
// import 'bootstrap/dist/css/bootstrap.min.css'
// import 'bootstrap/dist/js/bootstrap.min.js'
export default function Header(content){
    const [navbar, setNavbar]=useState(false)
    const changeBackground = () => {
        if (window.scrollY >= 650) {
            setNavbar(true)
        } else {
            setNavbar(false)
        }
    }
    useEffect(() => {
        changeBackground()
        // adding the event when scroll change background
        window.addEventListener("scroll", changeBackground)
    })

    return(
            //<header id="" className="">
                <Navbar id="header" fixed="top" expand="lg" data-bs-theme="dark" className={navbar ?"navbar active" : "navbar"}>
                    <Container>
                        <Navbar.Brand className="" href="#home">
                            <img
                                src={content.url}
                                alt={content.alt}
                                width="75"
                                height="75"
                                className="img-fluid"
                            />
                        </Navbar.Brand>
                        <Navbar.Toggle />
                        <Navbar.Collapse id="" className="justify-content-end">
                            <Nav >
                                {
                                    content.lis.map(li=>{
                                        return(<Nav.Link key={li.id} className='li' href={li.href}>{li.item}</Nav.Link>)
                                    })
                                }
                            </Nav>
                        </Navbar.Collapse>
                    </Container>
                </Navbar>
            //</header>
    )
}