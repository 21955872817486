import React from "react";
import "../assets/style/cta.css"
export function CtaForm(cta,setCta){}
export function Cta(content){
        return (<>
            <section key={content.id} id="cta" className="d-flex align-items-center">
                <div className="container">
                    <div className="section-title" >
                        <h2 style={{color:"#FFF"}}>{content.title}</h2>
                        <p>{content.subtitle}</p>
                    </div>
                </div>
            </section>
        </>)
}