import React from "react";
import {Link} from "react-router-dom";
import '../assets/style/hero.css'
import {Form, FormControl, FormGroup, FormLabel} from "react-bootstrap";

export function HeroForm(hero,setHero){
    const handleChangeHero=(event =>{
        const key = event.target.name
        const value = event.target.value
        setHero({ ...hero, [key]: value })
    })
    return (
        <>
            <FormGroup>
                <FormLabel>Titre</FormLabel>
                <FormControl type="text" name="title" value={hero.title} onChange={handleChangeHero} required/>
            </FormGroup>
            <FormGroup>
                <FormLabel>Image</FormLabel>
                <FormControl type="text" name="backgroundurl" value={hero.backgroundurl} onChange={handleChangeHero} required/>
            </FormGroup>
            <FormGroup>
                <FormLabel>Sous-titre</FormLabel>
                <FormControl type="text" name="subtitle" value={hero.subtitle} onChange={handleChangeHero} required/>
            </FormGroup>
            <FormGroup>
                <FormLabel>Lien bouton 1</FormLabel>
                <FormControl type="text" name="btn1url" value={hero.btn1url} onChange={handleChangeHero} required/>
            </FormGroup>
            <FormGroup>
                <FormLabel>Message bouton 1</FormLabel>
                <FormControl type="text" name="btn1value" value={hero.btn1value} onChange={handleChangeHero} required/>
            </FormGroup>
            <FormGroup>
                <FormLabel>Lien bouton 2</FormLabel>
                <FormControl type="text" name="btn2url" value={hero.btn2url} onChange={handleChangeHero} required/>
            </FormGroup>
            <FormGroup>
                <FormLabel>Message bouton 2</FormLabel>
                <FormControl type="text" name="btn2value" value={hero.btn2value} onChange={handleChangeHero} required/>
            </FormGroup>
    </>
)
}

export function Hero(hero) {
    let backgroundimage="linear-gradient(rgba(40,58,90, 0.5), rgba(40,58,90, 0.5)),url(\""+hero.backgroundurl+"\")"
    let colortitle=hero.colortitle
    let colorsubtitle=hero.colorsubtitle
    let fontsizetitle=hero.fontsizetitle
    let fontsizesubtitle=hero.fontsizesubtitle
    console.log(backgroundimage)
    return (
        <section key={hero.id} className="hero d-flex align-items-center" style={{backgroundImage:backgroundimage}}>
            <div className="container">
            <div className="row">
                    <div className="col-lg-12 d-flex flex-column justify-content-center hero-content">
                        <h1 style={{color:colortitle,fontSize:fontsizetitle}}>{hero.title}</h1>
                        <h2 style={{color:colorsubtitle,fontSize:fontsizesubtitle}}>{hero.subtitle}</h2>
                        <div className="d-flex justify-content-center justify-content-lg-start align-content-center">
                            <Link to={hero.btn1url} target="_blank" className="btn-signin scrollto">{hero.btn1value}
                                <box-icon name='right-arrow-alt' style={{verticalAlign:'top'}} color='#FFFFFF'/>
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}